import styled from "styled-components"

const backgroundColor = '#92DF9F40'
const fontFamily = "Poppins";
const titleColor = '#021246';
const solutionTitle = '#0F1922';
const Text = '#535B76';
const fontWeight = 400;


const SolutionContainer = styled.div`
display: flex;
justify-content: center;
background-color: ${backgroundColor};
width: 100%;
flex-direction: column;
flex-wrap: wrap;

padding: 36px 48px;
@media (max-width:1000px) {
        flex-direction: column;
        align-items: start;
    }
`
const SolutionTitle = styled.div`
display: flex;
font-family: ${fontFamily};
color: ${titleColor};
max-width: 80%;
font-weight: bold;
margin-bottom: 8px;
font-size: 24px;
`

const SolutionSubtitle = styled.div`
font-size: 36px;
font-family: ${fontFamily};
font-weight: 800;
color: ${solutionTitle};
width: 100%;
height: 44px;
display: flex;
@media (max-width: 1000px) {
    margin-bottom: 4%;
  }
  @media (max-width: 450px) {
    font-size: 34px;
  }
`

const Solution = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: space-between;
  margin-top: 32px;
  color: ${solutionTitle};
  font-family: ${fontFamily};
  font-weight: bold;
  align-items: start;
  grid-gap: 64px;
  
  @media (max-width: 1000px) {
    grid-template-columns: fit-content(90%);
  }
  
  @media (max-width: 600px) {
    grid-template-columns: fit-content(90%);
    padding-top: 15%;
  }
  @media (max-width: 450px) {
    grid-template-columns: fit-content(90%);
    padding-top: 35%;
  }
`
const SolutionGift = styled.div`
display: grid;
gap: 16px;
font-size: 18px;

h1 {
    color: ${solutionTitle};
}

span {
    border-top: 2px solid #D7DCF0;
    width: 80%;
}

p {
    color: ${Text};
    font-family: ${fontFamily};
    font-weight: ${fontWeight};
    line-height: 24px;
}

`
const SolutionCalc = styled.div`
display: grid;
gap: 16px;
font-size: 18px;

h1 {
    color: ${solutionTitle};
}

span {
    border-top: 2px solid #D7DCF0;
    width: 80%;
}

p {
    color: ${Text};
    font-family: ${fontFamily};
    font-weight: ${fontWeight};
    line-height: 24px;
}

`

const SolutionMagic = styled.div`
display: grid;
gap: 16px;
font-size: 18px;

h1 {
    color: ${solutionTitle};
}

span {
    border-top: 2px solid #D7DCF0;
    width: 80%;
}

p {
    color: ${Text};
    font-family: ${fontFamily};
    font-weight: ${fontWeight};
    line-height: 24px;
}
`
export {
    SolutionContainer,
    SolutionTitle,
    SolutionSubtitle,
    Solution,
    SolutionGift,
    SolutionCalc,
    SolutionMagic
} 