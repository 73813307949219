import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  HowWorksBox,
  HowWorksCard,
  HowWorksContainer,
  WorksTitle,
} from "./styled";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import slide1 from '../../assets/svg/slide1.svg';
import slide2 from '../../assets/svg/slide2.svg';
import slide3 from '../../assets/svg/slide3.svg';

const HowWorks = () => {
  const isMobile = useMediaQuery({ maxWidth: 1000 });

  const containerStyle = {
    width: isMobile ? '106%' : '100%',
  };

  return (
    <HowWorksContainer id="howwork" style={containerStyle}>

      {isMobile ? (
        <Carousel
          showArrows={false}
          autoPlay={true}
          emulateTouch={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
        >
          <div>
            <img src={slide1} alt="slide1" style={{ width: '100%' }} />
          </div>
          <div>
            <img src={slide2} alt="slide2" style={{ width: '100%' }} />
          </div>
          <div>
            <img src={slide3} alt="slide3" style={{ width: '100%' }} />
          </div>
        </Carousel>
      ) : (
        <>
          <WorksTitle>Como funciona</WorksTitle><HowWorksBox>
            <HowWorksCard>
              <img src={slide1} alt="slide1" />
              <img src={slide2} alt="slide2" />
              <img src={slide3} alt="slide3" />
            </HowWorksCard>
          </HowWorksBox></>
      )}
    </HowWorksContainer>
  );
};

export default HowWorks;
