import { styled } from "styled-components";

// Cores e Dimensões
const primaryColor = "#021246";
const secondaryColor = "#009c2c";
const backgroundColor = "#fff";
const fontSize = "16px";
const fontFamily = "Poppins";
// Estilos Compartilhados
const flexRow = `
  display: flex;
  flex-direction: row;
`;

// Componentes Estilizados
const NavBarContainer = styled.div`
  ${flexRow}
  align-items: center;
  border-bottom: 1px solid #D7DCF0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 8%;
  padding: 24px;
  background-color: white !important;
  box-sizing: border-box;
  text-align: left;
  font-size: ${fontSize};
  color: ${primaryColor};
  font-family: ${fontFamily};
 z-index: 3;

  @media (max-width: 1200px){
    padding: 10px 25px;
  }

  @media (max-width: 750px){
    align-items: end;
    padding-right: 40px;
  }

  @media (max-width: 500px){
  
  }

`;

const LogoBox = styled.div`
  img {
    width: 66%;
    padding-bottom: 4%;
  }
  @media (max-width: 1200px){
  img{
    width: 60%;
  }
  }
  @media (max-width: 750px){
  img{
    width: 95%;
    padding-left: 10%;
    padding-bottom: 0;
  }
  }
  @media (max-width: 400px){
    width: 100vw;
  img{
    width: 100%;
    }
  }
`;

const MainMenu = styled.div`
  ${flexRow}
  align-items: center;
  margin: 0;
  font-weight: normal;
  width: 100%;
  justify-content: space-between;
  h1{
    
    cursor: pointer; 
  }
  @media (max-width: 1200px){
    width: 90%;
}
@media (max-width: 750px){
   justify-content:flex-end;
}
  
`;

const NavLink = styled.h1`
  margin: 0;
  padding: 10px 0px;
  font-weight: normal;
  font-family: ${fontFamily};
  justify-content: space-between;
 
 

  @media (max-width: 1200px){
    font-size: 12px;

}
@media (max-width: 750px){
    display: none;
}
`;

const Login = styled.div`
  color: ${secondaryColor};
  display: flex;
  align-items: center;
  @media (max-width: 1200px){
    font-size: 12px;
  }
`;
const ResponsiveLogin = styled.div`
  color: black;
  display: flex;
  align-items: flex-start;
  display: none;
  font-size: 150%;

  @media (max-width: 750px){
    display: flex;

  }
  @media (max-width: 400px){
    font-size: 120%;
}
`;


const MenuContainer = styled.div`

 display: flex;
 align-items: flex-end;
 flex-direction: column;
  width: 750px;
  height: 450px;
  position: fixed;
  z-index: 2;
  top: 0;
@media (max-width:700px){
  width: 100%
}
`
const MenuResponsiveBox = styled.div`
   background-color:${secondaryColor};
   display: flex;
   align-items: center;
   flex-direction: column;
   width: 100%;
   height: 70%;
   padding-top: 55px;
   padding-right: 30px;
   @media (min-width: 750px) {
    display: none;
   }



`
const LinkMenuResponsive = styled.h1`
    font-weight: 600;
    font-family: ${fontFamily};
    font-size : 20px;
    color: white;
    margin-bottom: 15px;
    display: flex;

`

const BoxLinkMenuRs = styled.div`
  border-bottom: 1px solid white ;
  height: 90%;
  width: 95%;
  padding-top: 20px;


`
const BoxLinkMenuSpeak = styled.div`


  width: 95%;
  padding-top: 20px;


`
const DivClose = styled.div`
  width: 100%;
  height: 100%;

  position: fixed;
  z-index: 1
  ;

`

export {
  NavBarContainer,
  LogoBox,
  MainMenu,
  NavLink,
  Login,
  ResponsiveLogin,
  MenuContainer,
  MenuResponsiveBox,
  LinkMenuResponsive,
  BoxLinkMenuRs,
  BoxLinkMenuSpeak,
  DivClose
};
