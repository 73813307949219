import { HeaderContainer, Button, Gradient, ImgLogo, Title, SubTitle, Contend } from './styled'
import header from "../../assets/png/header.png"
import backLogo from '../../assets/svg/eagleTvLogo.svg'
import background from '../../assets/svg/backgroundheader.svg'

const Header = () => {

  const phoneNumber = '+5542991079288'
  const message = 'Olá, estive em seu site e estou interessado em seus serviços'
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  const imgLogo = () => [
    <img src={backLogo} alt='logo' style={{ width: '28vw', right: 0, position: 'absolute' }} />
  ]

  return (
    <HeaderContainer >
      <ImgLogo>
        {imgLogo()}
      </ImgLogo>

      <Gradient>
        <Title><h1>Gerenciamento de TV <span>corporativa simplificada
        </span></h1></Title>
        <SubTitle>Mude a Comunicação da Sua Empresa</SubTitle>
        <Contend>A solução USE VISION é desenvolvida para várias aplicações,
          incluindo TV Corporativa, Vitrine Digital, Cardápio Digital, Placa Digital e muito mais.
        </Contend>

        <a href={whatsappLink} target='_blank' rel="noopener noreferrer">
          <Button>
            SAIBA MAIS
          </Button>
        </a>

      </Gradient>
      <img src={background} alt="header" style={{ width: '100%', height: '100%' }} />

    </HeaderContainer>
  )
}

export default Header