import React, { useState, useEffect } from 'react';
import { CarouselContainer, Slide } from './styled';

interface CarouselProps {
  slides: JSX.Element[];
  autoPlay?: boolean;
  delay?: number;
}

const Carousel: React.FC<CarouselProps> = ({ slides, autoPlay = false, delay = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transitioning, setTransitioning] = useState(false);

  const handleTransitionEnd: React.TransitionEventHandler<HTMLDivElement> = (event) => {
    if (event.target === event.currentTarget) {
      setTransitioning(false);
    }
  };

  useEffect(() => {
    const goToNextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    };

    let interval: NodeJS.Timeout;

    if (autoPlay) {
      interval = setInterval(() => {
        setTransitioning(true);
        goToNextSlide();
      }, delay);
    }

    return () => {
      if (autoPlay) {
        clearInterval(interval);
      }
    };
  }, [autoPlay, delay, currentIndex, slides.length]);

  // const goToSlide = (index: number) => {
  //   setTransitioning(true);
  //   setCurrentIndex(index);
  // };

  // const goToNextSlide = () => {
  //   goToSlide((currentIndex + 1) % slides.length);
  // };

  // const goToPrevSlide = () => {
  //   goToSlide((currentIndex - 1 + slides.length) % slides.length);
  // };

  return (
    <CarouselContainer id='carrosel'>
      <Slide
        onTransitionEnd={handleTransitionEnd}
        className={transitioning ? 'transitioning' : ''}
        style={{
          width: `${slides.length * 100}%`,
          transform: `translateX(-${(currentIndex * 100) / slides.length}%)`,
        }}
      >
        {slides.map((slide, index) => (
          <div key={index} style={{ width: `${100 / slides.length}%` }}>
            {slide}
          </div>
        ))}
      </Slide>
      {/* <button onClick={goToPrevSlide}>Anterior</button>
      <button onClick={goToNextSlide}>Próximo</button> */}
    </CarouselContainer>
  );
};

export default Carousel;
