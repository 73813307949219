import { Analytics } from "@vercel/analytics/react";
import {
  About,
  Banner,
  Clients,
  Contact,
  Footer,
  NavBar,
  Header,
  WhatIs,
  Solutions,
  HowWorks,
} from "./components";
import GlobalStyle from "./GlobalStyles";

function App() {
  return (
    <main className="App">
      <GlobalStyle />
      <NavBar />
      <Header />
      <WhatIs />
      <HowWorks />
      <Clients />
      <Solutions />
      <Banner />
      <About />
      <Contact />
      <Footer />
      <Analytics />
    </main>
  );
}

export default App;
