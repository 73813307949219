import {
  TextContainer,
  Title,
  WhatIsContainer,
  WhatIsASideContainer,
  WhatSideContainer,
} from "./styled";
import tv from "../../assets/svg/Tv.svg";
const WhatIs = () => {
  return (
    <WhatIsContainer>
      <WhatIsASideContainer>
        <Title>
          <h1>O que é a USE VISION?</h1>
        </Title>
        <TextContainer>
          <p>
            {" "}
            <p>Nosso sistema é muito mais do que apenas um software de Sinalização
              Digital. É a plataforma que redefine a maneira como as empresas se comunicam internamente.
              Projetada para um Comunicação Interna eficiente e envolvente.
            </p>

            <p>  <br /> Imagine ter o poder de criar, programar e exibir conteúdos dinâmicos em suas
              telas de forma intuitiva e eficaz. Com a USE VISION, você pode fazer exatamente isso. Não importa
              o tamanho da sua empresa ou o número de telas que você possui, nossa solução é dimensionada para atender
              ás suas necessidades.
            </p>


            <p>  <br /> Com a facilidade de criação de programações personalizadas, você pode garantir que a mensagem
              certa seja entregue no momento certo. Nossa plataforma permite que você altere facilmente o conteúdo
              exibido, incorporando animações, notícias atualizadas e informações relevantes que manterão sua audiência
              envolvida e informada.
            </p>

          </p>
        </TextContainer>
      </WhatIsASideContainer>
      <WhatSideContainer>
        <img src={tv} alt="tv publish" />
      </WhatSideContainer>
    </WhatIsContainer>
  );
};

export default WhatIs;
