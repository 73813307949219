import React from 'react';
import {
  FooterAdress,
  FooterContact,
  FooterContainer,
  FooterCopy,
  FooterEmail,
  FooterEmailAdress,
  FooterIcons,
  FooterLogo,
  FooterNumberContact,
  FooterRow,
  FooterStreet,
  FooterContendBox,
} from './styled';
import logo from '../../assets/svg/logoFooter.svg';
import { FaLocationDot } from 'react-icons/fa6';
import { AiOutlineMail } from 'react-icons/ai';
import { BsTelephoneFill } from 'react-icons/bs';
import { RiTwitterXLine } from 'react-icons/ri';
import { BsLinkedin } from 'react-icons/bs';
import { BsFacebook } from 'react-icons/bs';
import { BsInstagram } from 'react-icons/bs'
import GlobalStyle from '../../GlobalStyles';

const Footer = () => {
  const openLink = (url: string | URL) => {
    window.open(url, '_blank', 'noopener noreferrer');
  };

  const phoneNumber = '+5542999872131'
  const message = 'Fala dev! Gostaria de um orçamento 🚀! Podemos conversar?'
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  const mapsAdress = 'https://maps.app.goo.gl/ithRmqZuZD3Xd9Vd8'
  return (
    <>
      <GlobalStyle />
      <FooterContainer>
        <FooterIcons>
          {/* <RiTwitterXLine
            onClick={() => openLink('https://twitter.com')}
          /> */}
          {/* <BsLinkedin
            onClick={() => openLink('https://linkedin.com')}
          /> */}
          <BsFacebook
            onClick={() => openLink('https://www.facebook.com/grupousevision')}
          />
          <BsInstagram
            onClick={() => openLink('https://www.instagram.com/usevisiondigital')}
          />
        </FooterIcons>
        <FooterContendBox
          style={{
            display: 'flex',
            columnGap: '160px',
            width: '100%',
            fontFamily: 'Carbona Test'
          }}>
          <FooterAdress>
            <div>
              <b>Endereço</b>
            </div>
                <FooterStreet>
                  <FaLocationDot style={{ marginRight: '8px' }} />
                  <a href={mapsAdress} target='_blank' rel="noreferrer">
                    Rua Emir Pedroso Scheidt, 02 - Jardim São Francisco, Imbituva-PR
                  </a>
                </FooterStreet>
          </FooterAdress>
          <FooterEmail>
            <div>
              <b>E-mail</b>
            </div>
            <div>
              <FooterEmailAdress>
                <AiOutlineMail style={{ marginRight: '8px' }} />
                contato@usevision.com
              </FooterEmailAdress>
            </div>
          </FooterEmail>
          <FooterContact>
            <div>
              <b>Telefone</b>
              <FooterNumberContact>
                <BsTelephoneFill style={{ marginRight: '8px' }} />
                +55 42 3028-0000
              </FooterNumberContact>
            </div>
          </FooterContact>
        </FooterContendBox>
        <FooterRow />
        <FooterLogo>
          <img
            src={logo}
            alt='logo Use Vision' />
          <FooterCopy>
            <p>
              Todos os direitos reservados. ©
            </p>
            <a 
            href={whatsappLink} 
            target='_blank' 
            rel="noopener noreferrer" 
            id='dev' style={{margin: '5%', fontSize: '11px', fontFamily: 'system-ui', fontWeight: 700}}>
              Desenvolvido por Grupo LM 💻
            </a>
          </FooterCopy>
        </FooterLogo>
      </FooterContainer></>
  );
};

export default Footer;
