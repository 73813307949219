import React from 'react'
import {
    ContactButton,
    ContactContainer,
    ContactSubscription,
    ContactTitle
} from './styled'
import { BsWhatsapp } from 'react-icons/bs'

const Contact = () => {
    const phoneNumber = '+5542991079288'
    const message = 'Olá, estive em seu site e estou interessado em seus serviços'
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    return (
        <>
            <ContactContainer id='contact' >
                <ContactTitle>
                    <b style={{fontWeight: 600}}>Contate-nos</b>
                    <ContactSubscription>
                        <b>Procura uma empresa experiente e habilidosa para projetos de TI personalizados?</b>
                    </ContactSubscription>
                    <ContactButton>
                        <a href={whatsappLink} target='_blank' rel="noopener noreferrer">
                            FALE CONOSCO 
                        </a>
                        <BsWhatsapp />
                    </ContactButton>
                </ContactTitle>
            </ContactContainer>
        </>
    )
}

export default Contact