import React from 'react';
import {
  MenuContainer,
  MenuResponsiveBox,
  LinkMenuResponsive,
  BoxLinkMenuRs,
  BoxLinkMenuSpeak,
  DivClose
} from './styled';
import logo from '../../assets/logo.svg';
import { BsArrowRight } from 'react-icons/bs'
import { Link } from 'react-scroll'

const ResponsiveNavBar = (props:any) => {

  const {Setshowmenu,showmenu}= props;

  <Link
  to="howwork" 
  spy={true} 
  smooth={true} 
  offset={-30} 
  duration={500} 
>
<LinkMenuResponsive>Como funciona</LinkMenuResponsive>

</Link>  

  return (
    <>
      <MenuContainer>
        <MenuResponsiveBox>
          <BoxLinkMenuRs>
                          <Link
                  to="howwork" 
                  spy={true} 
                  smooth={true} 
                  offset={-30} 
                  duration={500} 
                >
                <LinkMenuResponsive onClick={() => Setshowmenu(!showmenu)} >Como funciona</LinkMenuResponsive>

                </Link>  
                <Link
                  to="solutions" 
                  spy={true} 
                  smooth={true} 
                  offset={-30} 
                  duration={500} 
                >
                <LinkMenuResponsive onClick={() => Setshowmenu(!showmenu)} > Soluções</LinkMenuResponsive>

                </Link>  
                <Link
                  to="about" 
                  spy={true} 
                  smooth={true} 
                  offset={-30} 
                  duration={500} 
                >
                <LinkMenuResponsive onClick={() => Setshowmenu(!showmenu)} >Sobre Nós</LinkMenuResponsive>

                </Link>  
            
         
            

          </BoxLinkMenuRs>

          <BoxLinkMenuSpeak >
                 <Link
                  to="contact" 
                  spy={true} 
                  smooth={true} 
                  offset={-30} 
                  duration={500} 
                >
                <LinkMenuResponsive onClick={() => Setshowmenu(!showmenu)} >Fale Conosco</LinkMenuResponsive>

                </Link>  
            {/* <LinkMenuResponsive>Login
        <BsArrowRight/>
        </LinkMenuResponsive> */}

          </BoxLinkMenuSpeak>


        </MenuResponsiveBox>
      </MenuContainer>
      <DivClose onClick={() => Setshowmenu(!showmenu)} />
    </>
  );
};

export default ResponsiveNavBar;

