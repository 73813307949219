import { createGlobalStyle, styled } from 'styled-components';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800&display=swap');

  body {
    font-family: 'Poppins';
  }
`;

const fontTitle = '#000327';
const fontFamily = 'Poppins';
const fontSubtitle = '#021246';
const fontText = '#7D84A2';

export const ClientsContainer = styled.div`
width: 100%;
background-color: #F7FBFF;
display: flex;
flex-direction: column;

padding: 36px 48px;
`

export const ClientTitles = styled.div`
 h1 {
    color: ${fontTitle};
    font-family: ${fontFamily};
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 44px;
 }
`

export const ClientSubtitle = styled.div`
h2 {
    color: ${fontSubtitle};
    font-family: ${fontFamily};
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
}

p {
    color: ${fontText};
    font-family: ${fontFamily};
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    margin-top: 44px;
 }
 @media (max-width:350px) {
    h2{
      font-size: 2px;
    }
               
        }
`

export const ClientLogos = styled.div`
display: flex;
align-items: center;
gap: 130px;
margin-top: 4%;

img {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 89%;
}

  @media (max-width: 600px){
    gap: 95px;
    
  }
  @media (max-width: 480px){
    gap: 65px;
    
  }

`
export const ContainerLogo = styled.div`
z-index: 4;
        
  @media (max-width:1000px) {
         display:none
  }
`