import { styled, createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Carbona+Test:wght@600&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800&display=swap');
`;

const primaryColor = "#021246";
const fontTitle = 'Poppins'
const secondaryColor = "#2f4cbc";
const thirdColor = "#4ec86f"
const backgroundColor = "#fff";
const fontFamily = "Carbona Test";
const textColor = "#d7dcf0"

const FooterContainer = styled.div`
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: ${primaryColor};
  border-top: 1px solid ${secondaryColor};
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 48px;
  gap: 36px;
  min-width: 360px;
  text-align: left;
  font-size: 24px;
  color: ${backgroundColor};
  font-family: ${fontFamily};

  @media (max-width: 768px) {
    display: flex;
    padding: 24px;
    font-size: 18px;
  }

  @media (max-width: 480px) {
    display: flex;
    padding: 16px;
    font-size: 16px;
  }
`;

const FooterIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 36px;
  cursor: pointer;
`;

const FooterAdress = styled.div`
  position: relative;
  line-height: 36px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 24px;
  color: ${backgroundColor};
  font-family: ${fontTitle};
`;

const FooterStreet = styled.div`
display: flex;
align-items: baseline;
font-size: 14px;
color: ${textColor};
font-family: ${fontTitle};

a {
  margin-left: 6px;
  font-size: 14px;
  font-family: ${fontTitle};
}

@media (max-width: 768px) {
  padding: 24px 0px;
  font-size: 18px;
}
  
@media (max-width: 480px) {
  display: flex;
  padding: 16px 0px;
  font-size: 16px;
}
`;

const FooterEmail = styled.div`
  position: relative;
  line-height: 36px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 24px;
  color: ${backgroundColor};
  font-family: ${fontTitle};

  @media (max-width: 768px) {
      padding: 24px 0px;
      font-size: 25px;
    }
`;

const FooterEmailAdress = styled.div`
font-size: 14px;
display: flex;
flex-direction: row;
align-items: center;
width: 100%;
color: ${fontTitle};
@media (max-width: 768px) {
      padding: 24px 0px;
      font-size: 14px;
    }
`

const FooterContact = styled.div`
  position: relative;
  line-height: 36px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 24px;
  color: ${backgroundColor};
  font-family: ${fontTitle};
  
`;

const FooterNumberContact = styled.div`
font-size: 14px;
display: flex;
flex-direction: row;
align-items: center;
width: 100%;
color: ${textColor};
font-family: ${fontTitle};

@media (max-width: 768px) {
      padding: 24px 0px;
      font-size: 14px;
    }

`;

const FooterRow = styled.div`
  align-self: stretch;
  border-top: 1px solid ${thirdColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 0px 0px;
  gap: 32px;
  text-align: center;
  font-size: 14px;
`;

const FooterLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 36px;
  width: 100%;
  flex-direction: column;
`
const FooterCopy = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  position: relative;
  color: ${textColor};
  top: 32px;
`
const FooterContendBox = styled.div`
  display: flex;
  column-gap: 160px;
  width: 100%;
  font-family: ${fontTitle};
    @media(max-width:900px){
      flex-direction: column;
    }
`

export {
  FooterContainer,
  FooterIcons,
  FooterAdress,
  FooterStreet,
  FooterEmail,
  FooterContact,
  FooterRow,
  FooterLogo,
  FooterCopy,
  FooterEmailAdress,
  FooterNumberContact,
  FooterContendBox
};
