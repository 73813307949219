import Carousel from '../Carousel/Carousel';
import { BannerContainer } from './styled';
import { useMediaQuery } from 'react-responsive';

import banner1 from '../../assets/png/banner1.png';
import banner2 from '../../assets/png/banner2.png';
import banner3 from '../../assets/png/banner3.png';
import banner4 from '../../assets/png/banner4.png';

import mobile1 from '../../assets/png/mobileBanner01.png';
import mobile2 from '../../assets/png/mobileBanner02.png';
import mobile3 from '../../assets/png/mobileBanner03.png';
import mobile4 from '../../assets/png/mobileBanner04.png';

const Banner = () => {
  const isMobile = useMediaQuery({ maxWidth: 1000 });

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoPlay: true,
    autoplaySpeed: 2000,
    width: 500,
  };

  const slides = isMobile
    ? [
      <img src={mobile1} alt="Mobile Slide 1"  />,
      <img src={mobile2} alt="Mobile Slide 2"  />,
      <img src={mobile3} alt="Mobile Slide 3"  />,
      <img src={mobile4} alt="Mobile Slide 4"  />,
    ]
    : [
      <img src={banner1} alt="Slide 1" />,
      <img src={banner3} alt="Slide 3" />,
      <img src={banner2} alt="Slide 2" />,
      <img src={banner4} alt="Slide 4" />,
    ];

  return (
    <BannerContainer>
      <Carousel
        slides={slides}
        autoPlay={true}
        delay={5000}
      />
    </BannerContainer>
    
  );
};

export default Banner;
