import { Solution, SolutionCalc, SolutionContainer, SolutionGift, SolutionMagic, SolutionSubtitle, SolutionTitle } from "./styled"
import giftIcon from '../../assets/svg/giftIcon.svg';
import calculatorIcon from '../../assets/svg/calculatorIcon.svg';
import magicIcon from '../../assets/svg/magicIcon.svg';



const Solutions = () => {
  return (
    <>
      <SolutionContainer id="solutions">
        <SolutionTitle> Soluções </SolutionTitle>
        <SolutionSubtitle>Os benefícios para o seu negócio são imensos!</SolutionSubtitle>
        <Solution>
          <SolutionGift>
            <img src={giftIcon} alt="gift-logo" />
            <h1>
              Soluções Flexíveis
            </h1>
            <span />
            <p>
              Adaptamos a USE VISION para atender às necessidades específicas da sua empresa. Desde agências de marketing até comunicação interna, temos a solução certa para você.
            </p>
          </SolutionGift>
          <SolutionCalc>
            <img src={calculatorIcon} alt="calculator-icon" />
            <h1>
              Eficiência e Economia
            </h1>
            <span />
            <p>
              Economize tempo e recursos com atualizações remotas. Reduza o desperdício de papel e contribua para o meio ambiente.
            </p>
          </SolutionCalc>
          <SolutionMagic>
            <img src={magicIcon} alt="" />
            <h1>
              Versatilidade Inigualável
            </h1>
            <span />
            <p>
              A Solução USE VISION é adequada para várias aplicações, incluindo TV Corporativa, Vitrine Digital, Cardápio Digital e Placa Digital.
            </p>
          </SolutionMagic>
        </Solution>
      </SolutionContainer>
    </>

  )
}

export default Solutions