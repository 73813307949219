import React from "react";
import {
  NavBarContainer,
  LogoBox,
  MainMenu,
  NavLink,
  Login,
  ResponsiveLogin,
} from "./styled";
import logo from "../../assets/svg/logo.svg";
import { BsArrowRight } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import { useState } from "react";
import ResponsiveNavBar from "./ResponsiveNavBar";
import { Link } from "react-scroll";
import { CarouselContainer } from "../Carousel/styled";

const NavBar = () => {
  const [showmenu, Setshowmenu] = useState(false);

  return (
    <>
      <NavBarContainer>
        <LogoBox>
          <img src={logo} alt="logo" />
        </LogoBox>

        <MainMenu>
          <Link
            to="carrosel"
            spy={true}
            smooth={true}
            offset={-30}
            duration={500}
          >
            {/* <NavLink>Nossos Produtos</NavLink> */}
          </Link>
          <Link
            to="howwork"
            spy={true}
            smooth={true}
            offset={-30}
            duration={500}
          >
            <NavLink>Como funciona</NavLink>
          </Link>
          <Link
            to="solutions"
            spy={true}
            smooth={true}
            offset={-30}
            duration={500}
          >
            <NavLink>Soluções</NavLink>
          </Link>

          <Link to="about" spy={true} smooth={true} offset={-30} duration={500}>
            <NavLink>Sobre Nós</NavLink>
          </Link>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={-300}
            duration={500}
          >
            <NavLink>Contato</NavLink>
          </Link>

          {/* <Login>
            Login
            <BsArrowRight />
          </Login> */}
          <ResponsiveLogin>
            {/* <Login>
            Login
            <BsArrowRight />
          </Login> */}
            {showmenu ? (
              <IoCloseOutline onClick={() => Setshowmenu(!showmenu)} />
            ) : (
              <FiMenu onClick={() => Setshowmenu(!showmenu)} />
            )}
          </ResponsiveLogin>
        </MainMenu>
      </NavBarContainer>
      {showmenu && (
        <ResponsiveNavBar Setshowmenu={Setshowmenu} showmenu={showmenu} />
      )}
    </>
  );
};

export default NavBar;
