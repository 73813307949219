import React from 'react'
import { AboutBar, AboutButton, AboutContainer, AboutSubtitle, AboutText, AboutTitle } from './styled'

const About = () => {
    const phoneNumber = '+5542991079288'
    const message = 'Olá, estive em seu site e gostaria de agendar uma visita'
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    return (
        <>
            <AboutContainer id='about'>
                <AboutTitle>
                    <b>Quem somos nós?</b>
                </AboutTitle>
                <AboutSubtitle>
                    <b>
                        Uma empresa de soluções de Digital Signage do Sul do Brasil
                    </b>
                </AboutSubtitle>
                <AboutBar />
                <AboutText>
                    <b>Estamos empenhados em inovar a maneira como seus consumidores
                        <span className='custom-color'> conectam-se à sua marca, através de telas e conteúdo inteligente!</span>
                    </b>
                    <b>Desde 2019, a USE VISION <span className='custom-color'> está comprometida com a inovação</span> na forma como os consumidores se <span className='custom-color'> relacionam com as marcas.</span> Com um histórico de sucesso sólido, servimos uma ampla gama de clientes no setor de marketing, capacitando-os a criar conexões únicas e memoráveis com seu público.</b>
                    <b>Nossa jornada de <span className='custom-color'> inovação e excelência continua,</span> e estamos entusiasmados em criar <span className='custom-color'> experiências de marca verdadeiramente cativantes.</span></b>
                </AboutText>
                <AboutButton>
                    <a href={whatsappLink} target='_blank' rel="noopener noreferrer">
                        AGENDE UMA VISITA
                    </a>
                </AboutButton>
            </AboutContainer>
        </>
    )
}

export default About