import { createGlobalStyle, styled } from 'styled-components';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800&display=swap');

  body {
    font-family: 'Poppins';
  }
`;

export const WhatIsContainer = styled.div`
    max-width: 100%;
    margin-top: 2%;
    display: flex;
    margin-bottom: 3%;
    align-items: center;

    @media (max-width:1000px) {
        flex-direction: column;
        align-items: center;
    }
`
export const WhatSideContainer = styled.div`
    background-color: #4EC86F;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    @media (max-width:1000px) {
        margin-top: 2%;
        width: 90%;
        
    }
`
    
export const WhatIsASideContainer = styled.div`
    background-color: white;
    width: 60%;
    padding: 0px 50px;
    @media (max-width:1000px) {
        width:100%
    }


`

export const Title = styled.div`

h1 {
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 2%;
}
    color: red;
    width: 100%;
    display: flex;
    justify-content: start;
    color: #009C2C;
    font-size: 36px;
    font-weight: 800;
    line-height: 44px;
    letter-spacing: 0.72px;
    word-wrap: break-word;
    margin-bottom: 10px;

`

export const TextContainer = styled.div`

p{
    color: #535B76;
    font-size: 18px;
    font-family: Carbona Test;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1.44px;
    word-wrap: break-word;
    font-family: 'Poppins', sans-serif;
}
    

`