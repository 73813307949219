import { createGlobalStyle, styled } from 'styled-components';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Carbona+Test:wght@600&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800&display=swap');

  body {
    font-family: 'Poppins', sans-serif;
  }
`;

const themeColors = {
  primary: '#0F1922',
  secondary: '#021246',
  accent: '#D7DCF0',
  text: '#535B76',
  background: '#FFFFFF',
  button: '#009C2C',
  border: '#92DF9F',
};

// Container
const AboutContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  margin: 0 auto;
  padding: 0px 48px 0px;
  width: 100%;
  position: relative;
  margin-bottom: 2%;
  @media (max-width: 1000px) {
    
  }

  @media (max-width: 768px) {
    padding: 24px;
    font-size: 18px;
  }

  @media (max-width: 480px) {
    padding: 16px;
    font-size: 16px;
  }
`;

// Title
const AboutTitle = styled.div`
  font-size: 24px;
  font-family: 'Poppins';
  font-weight: bold;
  color: ${themeColors.secondary};
  margin-bottom: 10px;
  @media (max-width: 1000px) {
   padding: 0px 1%;
  }
`;

// Subtitle
const AboutSubtitle = styled.div`
  font-size: 36px;
  font-weight: bold;
  font-family: 'Poppins';
  color: ${themeColors.primary};
  @media (max-width: 1000px) {
   padding: 0px 1%;
  }
`;

// Bar
const AboutBar = styled.div`
  width: 100%;
  border-top: 2px solid ${themeColors.accent};
`;

// About Text
const AboutText = styled.div`
  line-height: 24px;
  letter-spacing: 1.44px;
  display: flex;
  padding: 0;
  align-items: flex-start;
  gap: 124px;
  align-self: stretch;
  font-family: 'Poppins';
  color: ${themeColors.text};

  .custom-color {
    color: ${themeColors.text};
    font-weight: 600;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 30px;
    padding: 0px 1%;
  }
`;

// Button
const AboutButton = styled.button`
  margin-top: 32px;
  border-radius: 16px;
  background-color: ${themeColors.button};
  border: 2px solid ${themeColors.border};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
  gap: 4px;
  font-size: 18px;
  color: ${themeColors.background};
  font-family: 'Carbona Test';
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: darken(${themeColors.button}, 10%);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${themeColors.border};
  }
`;

export { AboutContainer, AboutTitle, AboutSubtitle, AboutText, AboutButton, AboutBar };
