import React from 'react';
import { ClientsContainer, ClientTitles, ClientSubtitle, ClientLogos } from './styled';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useMediaQuery } from 'react-responsive';

import logo1 from '../../assets/png/naiverth.png';
import logo2 from '../../assets/png/italy.png';
import logo3 from '../../assets/png/gbras.png';
import logo4 from '../../assets/png/scturbo.png';

const Clients = () => {
  const isMobile = useMediaQuery({ maxWidth: 500 });

  const logos = [
    { src: logo1, alt: 'logo1', style: { width: '45vw' } },
    { src: logo2, alt: 'logo2', style: { width: '27vw' } },
    { src: logo3, alt: 'logo3', style: { width: '27vw' } },
    // { src: logo4, alt: 'logo4', style: { width: '12vw' } },
  ];

  return (
    <ClientsContainer>
      <ClientTitles>
        <h1>Nossos Clientes</h1>
      </ClientTitles>
      <ClientSubtitle>
        <h2>Descubra as empresas que a USE VISION está revolucionando na área de comunicação interna.</h2>
        <p>Construímos nossa reputação com base na confiança, qualidade e compromisso.</p>
      </ClientSubtitle>
      <ClientLogos>
        {isMobile ? (
          <Carousel
            showArrows={false}
            autoPlay={true}
            emulateTouch={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
          >
            {logos.map((logo, index) => (
              <div key={index}>
                <img src={logo.src} alt={logo.alt} style={logo.style as React.CSSProperties} />
              </div>
            ))}
          </Carousel>
        ) : (
          logos.map((logo, index) => (
            <div key={index}>
              <img src={logo.src} alt={logo.alt} style={logo.style as React.CSSProperties} />
            </div>
          ))
        )}
      </ClientLogos>
    </ClientsContainer>
  );
};

export default Clients;
