import { createGlobalStyle, styled } from 'styled-components';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800&display=swap');

  body {
    font-family: 'Poppins', sans-serif;
  }
`;

export const BannerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  position: relative;
`;

