import { createGlobalStyle, styled } from 'styled-components';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800&display=swap');


`;

export const HeaderContainer =  styled.div`
        margin-top: 70px;
        position: relative;
        display: flex;
        width: 100%;
        height: 400px;
        margin-bottom: 40px;
        font-family: 'Poppins';
        

        img{
                object-fit: cover    ;
                
        }
        @media (max-width:1000px) {
                height: 420px;
        }
        @media (max-width:600px) {
                height: 560px;
        }
        @media (max-width:400px) {
                height: 600px;
        }
`
export const Button = styled.div`
        
        background-color: green;
        color: white;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 16px;
        letter-spacing: 2.16px;
        word-wrap: break-word;
        width: 100%;
        height: 100%;
        padding: 24px 32px;
        background: #009C2C;
        border-radius: 16px;
        border: 2px #92DF9F solid;
        justify-content: center;
        align-items: center;
        gap: 4px;
        display: inline-flex;
        font-family: 'Poppins';
        
        

        a{
                width: 100%;
                height: 100%;
        }
        &:hover{
                cursor: pointer;
        }


`
export const Gradient = styled.div`
        padding: 0 5%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgb(255,255,255);
        background: linear-gradient(0deg,rgba(255,255,255,0) 0% ,rgba(255,255,255,1) 55%, rgba(255,255,255,1) 55%,rgba(255,255,255,1) 55%);
        @media (max-width:1000px) {
                padding: 0 7%;
        }
`
export const ImgLogo = styled.div`
        z-index: 2;
        
        @media (max-width:1000px) {
                display:none
        }
`
export const Title = styled.div`
        max-width: 820px;
h1{
        color: #021246;
        font-size: 36px;
        font-weight: 800;
        line-height: 44px;
        letter-spacing: 0.72px;
        word-wrap: break-word;
        font-family: 'Poppins';
        
        
}

span{
        color: #009C2C;
}

`

export const SubTitle = styled.div`
        max-width: 820px;       
        color: #0F1922;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        word-wrap: break-word;
        font-family: 'Poppins';

`

export const Contend = styled.div`
        max-width: 820px;
        color: #535B76;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 1.44px;
        word-wrap: break-word;
        font-family: 'Poppins';

`