import styled from "styled-components"

const backgroundColor = '#92DF9F40'
const fontFamily = "Poppins";
const titleColor = '#021246';
const solutionTitle = '#0F1922';
const Text = '#535B76';
const fontWeight = 400;


const HowWorksContainer = styled.div`
display: flex;
justify-content: center;
width: 100%;
flex-direction: column;
padding-top: 2%;

img {
    height: 90%;
    width: 80vw;
}

@media (max-width:500px) {
    padding: 5px 0px;
}

img {
    height: auto;
    max-width: 100%;
}

`
const WorksTitle = styled.div`
display: flex;
font-family: ${fontFamily};
color: ${titleColor};
font-weight: bold;
margin-bottom: 8px;
font-size: 24px;
padding-left:4%;

@media (max-width:500px) {
    padding-left: 20px;
}
`

const HowWorksBox = styled.div`
display: flex;
`
const HowWorksCard = styled.div`
display: flex;
justify-content: center;

img {
    height: 80%;
}

@media (max-width:1150px) {
    img{
      height: 65%;     
    }     
}
`

export {
    HowWorksContainer,
    WorksTitle,
    HowWorksBox,
    HowWorksCard
} 