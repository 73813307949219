import styled from 'styled-components';

export const CarouselContainer = styled.div`
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  position: relative;
 
`;

export const Slide = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: opacity 2s ease-in-out;

  &.transitioning {
    transition: none;
  }

  img {
    width: 100%;
    height: 73vh;
    object-fit: cover;
    opacity: 1;
    transition: opacity 2s ease-in-out;
  }

  @media (max-width: 600px) {
    img {
      width: 100vw;
      height: 90vw;
      align-items: center;
      display: flex;
      margin-right: 8px;
    }
  }

  &.cross-fade-enter, &.cross-fade-leave-active {
    opacity: 0;
  }
`;
