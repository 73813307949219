import { createGlobalStyle, styled } from 'styled-components';


export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Carbona+Test:wght@600&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800&display=swap');
`;


const primaryColor = '#009C2C';
const secondaryColor = '#92DF9F';
const textColor = '#FFFFFF';
const fontTitle = 'Poppins'

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 70px;
  font-family: ${fontTitle};
`;

const ContactTitle = styled.div`
  width: 100%;
  border-top: 1px solid #d7dcf0;
  border-bottom: 1px solid #d7dcf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 48px;
  gap: 24px;
  text-align: left;
  font-size: 24px;
  color: #0f1922;
  @media(max-width:700px){
    padding: 30px;
  }
  @media(max-width:450px){
    padding: 25px;
  }
`;

const ContactSubscription = styled.div`
letter-spacing: 0.08em;
line-height: 24px;
width: 100%;
display: flex;
text-align: left;
font-size: 18px !important;
color: #535b76;
`;

const ContactButton = styled.button`
  border-radius: 16px;
  background-color: ${primaryColor};
  border: 2px solid ${secondaryColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
  gap: 4px;
  font-size: 18px;
  color: ${textColor};
  margin-top: 32px;
`;

export { ContactTitle, ContactSubscription, ContactContainer, ContactButton };
